@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --button-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}
